import React from 'react';
import { TestimonialCardProps } from '../types/testimonials.types';
import { Quote } from 'lucide-react';

const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial }) => {
  return (
    <div className="bg-black border border-white/10 p-8 hover:border-white/30 transition-all duration-300">
      <Quote className="w-8 h-8 text-white/40 mb-4" strokeWidth={1.5} />
      <blockquote className="text-lg text-gray-300 mb-6 font-light">
        "{testimonial.quote}"
      </blockquote>
      <div className="flex items-center">
        {testimonial.imageUrl && (
          <img
            src={testimonial.imageUrl}
            alt={testimonial.author}
            className="w-12 h-12 rounded-full mr-4 object-cover"
          />
        )}
        <div>
          <div className="text-white font-semibold">{testimonial.author}</div>
          <div className="text-gray-400 text-sm">
            {testimonial.position}, {testimonial.company}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;