import { FAQ } from '../types/faq.types';

export const faqs: FAQ[] = [
  {
    question: "How long does it take to implement your solutions?",
    answer: "Implementation typically takes 2-4 weeks, depending on your business needs and system complexity. Our team ensures a smooth transition with minimal disruption to your operations."
  },
  {
    question: "Is this suitable for small businesses?",
    answer: "Absolutely! Our solutions are scalable and can be customized for businesses of all sizes. We offer flexible packages that grow with your business needs."
  },
  {
    question: "What kind of results can I expect?",
    answer: "Our clients typically see a 30% reduction in response times, 50% decrease in manual tasks, and significant improvements in customer satisfaction within the first 3 months."
  },
  {
    question: "How secure is your AI system?",
    answer: "Security is our top priority. We employ enterprise-grade encryption, regular security audits, and comply with industry standards to protect your data."
  }
];