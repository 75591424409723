import { MessageSquare, GitMerge, Mail } from 'lucide-react';
import { Solution } from '../types/solutions.types';

export const solutionsConfig: Solution[] = [
  {
    pain: 'Wasting time on repetitive customer inquiries?',
    solution: 'Respond instantly, 24/7',
    icon: MessageSquare,
    description: 'AI-powered chatbots handle common questions automatically'
  },
  {
    pain: 'Missing appointments due to manual scheduling?',
    solution: 'Streamline your sales pipeline',
    icon: GitMerge,
    description: 'Seamless CRM integration for automated scheduling'
  },
  {
    pain: 'Low engagement on email campaigns?',
    solution: 'Reach the right audience at the right time',
    icon: Mail,
    description: 'Smart email automation with AI-driven insights'
  }
];