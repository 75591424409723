import React from 'react';

const AnimatedBackground = () => {
  return (
    <>
      {/* Grid pattern background */}
      <div className="absolute inset-0 grid-pattern opacity-20" />
      
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black to-black" />
      
      {/* Animated dots */}
      <div className="absolute inset-0">
        <div className="absolute w-2 h-2 bg-white rounded-full animate-pulse" 
          style={{ top: '20%', left: '10%' }} />
        <div className="absolute w-2 h-2 bg-white rounded-full animate-pulse" 
          style={{ top: '60%', left: '80%', animationDelay: '1s' }} />
        <div className="absolute w-2 h-2 bg-white rounded-full animate-pulse" 
          style={{ top: '80%', left: '30%', animationDelay: '2s' }} />
      </div>
    </>
  );
};

export default AnimatedBackground;