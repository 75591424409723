import React from 'react';
import { MetricsProps } from '../types/about.types';

const Metrics = ({ metrics }: MetricsProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
      {metrics.map((metric, index) => (
        <div key={index} className="text-center p-6 border border-white/10 hover:border-white/20">
          <div className="text-2xl font-light terminal-glow mb-2">
            {metric.value}
          </div>
          <div className="text-sm text-gray-400 font-light">
            {metric.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Metrics;