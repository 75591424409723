import { AboutConfig } from '../types/about.types';

export const aboutConfig: AboutConfig = {
  hero: {
    title: "Revolutionizing Business Through AI",
    subtitle: "Welcome to RR AI, where we harness the power of artificial intelligence to transform how businesses connect, communicate, and grow in the digital age."
  },
  mission: {
    description: "Our mission is to empower businesses with intelligent automation tools that save time, reduce costs, and create exceptional customer experiences. We understand that efficiency is key to staying ahead, which is why we tailor every solution to meet your unique business needs."
  },
  vision: {
    description: "To become the global leader in AI-driven business automation, empowering organizations worldwide to achieve unprecedented levels of efficiency, growth, and success through innovative technology solutions."
  },
  metrics: [
    { value: "500+", label: "Systems Automated" },
    { value: "99.9%", label: "Uptime Guaranteed" },
    { value: "24/7", label: "Monitoring Active" },
    { value: "<10ms", label: "Response Time" }
  ]
} as const;