import React from 'react';
import TestimonialCard from './components/TestimonialCard';
import StatisticsGrid from './components/StatisticsGrid';
import { testimonials, statistics } from './config/testimonialsConfig';

const Testimonials = () => {
  return (
    <section id="testimonials" className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <p className="text-sm text-gray-400 mb-2 font-light">$ system.testimonials</p>
          <h2 className="text-4xl font-light mb-6 terminal-glow">
            Hear From Our Clients
          </h2>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </div>

        <StatisticsGrid statistics={statistics} />
      </div>
    </section>
  );
};

export default Testimonials;