import React from 'react';
import QuickLinks from './components/QuickLinks';
import ContactInfo from './components/ContactInfo';
import Legal from './components/Legal';
import { footerConfig } from './config/footerConfig';

const Footer = () => {
  const { quickLinks, contactInfo, legal, copyright } = footerConfig;

  return (
    <footer className="bg-black border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <QuickLinks links={quickLinks} />
          <ContactInfo info={contactInfo} />
          <Legal links={legal} />
        </div>
        
        <div className="pt-8 border-t border-white/10 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm">
            {copyright.text} {copyright.tagline}
          </p>
          <div className="flex items-center mt-4 md:mt-0">
            <span className="text-sm text-gray-400 font-light">
              {'>'} RR_AI<span className="animate-pulse">_</span>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;