import React from 'react';
import { Shield } from 'lucide-react';
import { trustBadgesConfig } from '../config/trustBadgesConfig';

const TrustBadges = () => {
  return (
    <div className="mt-16">
      <p className="text-sm text-gray-400 mb-6">Trusted By Industry Leaders</p>
      <div className="flex justify-center items-center gap-8 flex-wrap">
        {trustBadgesConfig.map((company) => (
          <div key={company} 
            className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors">
            <Shield className="w-4 h-4" />
            <span className="text-sm font-light">{company}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustBadges;