import React from 'react';
import { ServiceCardProps } from '../types/services.types';

const ServiceCard: React.FC<ServiceCardProps> = ({ 
  icon: Icon, 
  title, 
  description, 
  link 
}) => {
  return (
    <div className="group relative bg-black border border-white/10 p-6 
      hover:border-white/30 transition-all duration-300 hover-glow">
      <div className="h-full flex flex-col">
        <div className="mb-4">
          <Icon className="w-8 h-8 text-white opacity-80 group-hover:opacity-100 
            transition-opacity" strokeWidth={1.5} />
        </div>
        
        <h3 className="text-xl font-semibold mb-3 terminal-glow">{title}</h3>
        
        <p className="text-gray-400 text-sm mb-6 flex-grow">{description}</p>
        
        <a 
          href={link}
          className="inline-flex items-center text-sm text-white hover:text-gray-300 
            transition-colors font-light"
        >
          <span className="mr-2">{'$'}</span>
          Learn More
          <span className="ml-2 group-hover:translate-x-1 transition-transform">→</span>
        </a>
      </div>
    </div>
  );
};

export default ServiceCard;