import React from 'react';
import FAQList from './components/FAQList';
import { faqs } from './config/faqConfig';

const FAQ = () => {
  return (
    <section id="faq" className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <p className="text-sm text-gray-400 mb-2 font-light">$ system.faq</p>
          <h2 className="text-4xl font-light mb-4 terminal-glow">
            Got Questions? We've Got Answers.
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Everything you need to know about our AI automation solutions.
          </p>
        </div>

        <FAQList faqs={faqs} />
      </div>
    </section>
  );
};

export default FAQ;