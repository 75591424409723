import React from 'react';
import Navbar from './components/layout/Navbar';
import Hero from './components/sections/hero';
import About from './components/sections/about';
import Solutions from './components/sections/Solutions';
import Services from './components/sections/Services';
import Testimonials from './components/sections/testimonials/Testimonials';
import FAQ from './components/sections/faq/FAQ';
import Contact from './components/sections/contact';
import ClosingCTA from './components/sections/closing';
import Footer from './components/layout/footer';

function App() {
  return (
    <main className="min-h-screen bg-black text-white">
      <Navbar />
      <Hero />
      <About />
      <Solutions />
      <Services />
      <Testimonials />
      <FAQ />
      <Contact />
      <ClosingCTA />
      <Footer />
    </main>
  );
}

export default App;