import React from 'react';
import { FooterLink } from '../types/footer.types';

interface LegalProps {
  links: FooterLink[];
}

const Legal: React.FC<LegalProps> = ({ links }) => (
  <div>
    <h3 className="text-white font-semibold mb-4">Legal</h3>
    <ul className="space-y-2">
      {links.map(({ label, href }) => (
        <li key={label}>
          <a 
            href={href}
            className="text-gray-400 hover:text-white transition-colors duration-200"
          >
            {label}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Legal;