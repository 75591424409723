import React from 'react';

interface PainPointProps {
  text: string;
}

const PainPoint = ({ text }: PainPointProps) => {
  return (
    <div className="p-6 border border-white/10 hover:border-white/20">
      <p className="text-gray-400 font-light">{text}</p>
    </div>
  );
};

export default PainPoint;