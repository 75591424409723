import { FooterConfig } from '../types/footer.types';

export const footerConfig: FooterConfig = {
  quickLinks: [
    { label: 'About', href: '#about' },
    { label: 'Services', href: '#services' },
    { label: 'FAQ', href: '#faq' },
    { label: 'Contact', href: '#contact' }
  ],
  contactInfo: {
    email: 'hello@rrai.com',
    socialLinks: []
  },
  legal: [
    { label: 'Privacy Policy', href: '/privacy' },
    { label: 'Terms of Service', href: '/terms' }
  ],
  copyright: {
    text: '© 2024 RR AI.',
    tagline: 'Designed for the future.'
  }
} as const;