import React from 'react';
import { ArrowRight } from 'lucide-react';
import { closingConfig } from './config/closingConfig';
import Button from '../../ui/Button';

const ClosingCTA = () => {
  const { title, description, ctaText } = closingConfig;

  return (
    <section className="relative py-32 bg-black overflow-hidden">
      {/* Animated background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-900/20 via-black to-black animate-gradient" />
      
      {/* Grid pattern overlay */}
      <div className="absolute inset-0 grid-pattern opacity-10" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-5xl font-light mb-8 terminal-glow">
          {title}
        </h2>
        
        <p className="text-xl text-gray-400 mb-12 max-w-2xl mx-auto font-light">
          {description}
        </p>

        <a href="#contact">
          <Button 
            icon={ArrowRight}
            className="group bg-white text-black py-6 px-12 text-lg mx-auto 
              hover:bg-gray-100 transform hover:scale-105 transition-all duration-200"
          >
            {ctaText}
          </Button>
        </a>
      </div>
    </section>
  );
};

export default ClosingCTA;