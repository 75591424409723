import React from 'react';
import SolutionCard from './solutions/components/SolutionCard';
import PainPoint from './solutions/components/PainPoint';
import { solutionsConfig } from './solutions/config/solutionsConfig';

const Solutions = () => {
  return (
    <section id="solutions" className="py-32 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-light terminal-glow mb-4">
            Why Businesses Love RR AI
          </h2>
        </div>

        <div className="grid md:grid-cols-2 gap-16">
          {/* Pain Points Column */}
          <div className="space-y-8">
            <h3 className="text-xl font-light mb-8">Common Challenges</h3>
            {solutionsConfig.map((item, index) => (
              <PainPoint key={`pain-${index}`} text={item.pain} />
            ))}
          </div>

          {/* Solutions Column */}
          <div className="space-y-8">
            <h3 className="text-xl font-light mb-8">Our Solutions</h3>
            {solutionsConfig.map((solution, index) => (
              <SolutionCard key={`solution-${index}`} solution={solution} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;