import React from 'react';
import ServiceCard from './ServiceCard';
import { servicesConfig } from '../config/servicesConfig';

const ServicesGrid: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {servicesConfig.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
    </div>
  );
};

export default ServicesGrid;