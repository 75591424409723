import React from 'react';
import SectionHeader from './services/components/SectionHeader';
import ServicesGrid from './services/components/ServicesGrid';

const Services = () => {
  return (
    <section id="services" className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionHeader 
          title="Our AI-Powered Solutions"
          subtitle="$ system.solutions"
          description="Cutting-edge AI solutions designed to transform your business operations and drive growth."
        />
        <ServicesGrid />
      </div>
    </section>
  );
};

export default Services;