import React from 'react';
import { AboutHeroProps } from '../types/about.types';
import { Zap } from 'lucide-react';

const AboutHero = ({ content }: AboutHeroProps) => {
  return (
    <div className="text-center mb-20">
      <div className="inline-flex items-center justify-center gap-2 mb-4">
        <Zap className="w-5 h-5 text-white" />
        <span className="text-sm text-gray-400 font-light">$ about.us</span>
      </div>
      <h1 className="text-4xl md:text-5xl font-light mb-6 terminal-glow">
        {content.title}
      </h1>
      <p className="text-xl text-gray-400 max-w-3xl mx-auto font-light leading-relaxed">
        {content.subtitle}
      </p>
    </div>
  );
};

export default AboutHero;