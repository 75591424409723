import React from 'react';
import { contactConfig } from '../config/contactConfig';

const ContactHeader = () => {
  const { title, subtitle, description } = contactConfig;
  
  return (
    <div className="text-center mb-16">
      <p className="text-sm text-gray-400 mb-2 font-light">{subtitle}</p>
      <h2 className="text-4xl font-light mb-4 terminal-glow">
        {title}
      </h2>
      <p className="text-gray-400 max-w-2xl mx-auto">
        {description}
      </p>
    </div>
  );
};

export default ContactHeader;