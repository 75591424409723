import React from 'react';
import { SolutionCardProps } from '../types/solutions.types';

const SolutionCard = ({ solution }: SolutionCardProps) => {
  const { icon: Icon, solution: title, description } = solution;
  
  return (
    <div className="p-6 border border-white/10 hover:border-white/20 
      transition-all duration-300 hover-glow">
      <div className="flex items-start gap-4">
        <Icon className="w-6 h-6 text-white" />
        <div>
          <h4 className="font-semibold mb-2">{title}</h4>
          <p className="text-sm text-gray-400">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SolutionCard;