import React from 'react';
import ContactForm from './components/ContactForm';
import ContactHeader from './components/ContactHeader';

const Contact = () => {
  return (
    <section id="contact" className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ContactHeader />
        <ContactForm />
      </div>
    </section>
  );
};

export default Contact;