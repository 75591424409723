import React from 'react';
import { VisionProps } from '../types/about.types';
import { Telescope } from 'lucide-react';

const Vision = ({ content }: VisionProps) => {
  return (
    <div className="mb-20">
      <div className="max-w-3xl mx-auto">
        <div className="flex items-start gap-4 p-8 border border-white/10 hover:border-white/20 transition-all">
          <Telescope className="w-6 h-6 text-white shrink-0 mt-1" />
          <div>
            <h2 className="text-2xl font-light mb-4 terminal-glow">Our Vision</h2>
            <p className="text-gray-400 font-light leading-relaxed">
              {content.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;