import React, { useState } from 'react';
import { ContactFormState } from '../types/contact.types';
import { ArrowRight } from 'lucide-react';

const ContactForm = () => {
  const [formData, setFormData] = useState<ContactFormState>({
    name: '',
    email: '',
    company: '',
    goals: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
      <div className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-light text-gray-400 mb-2">
            $ your.name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            className="w-full bg-black border border-white/20 px-4 py-3 text-white 
              focus:border-white/40 focus:outline-none transition-colors"
            placeholder="John Doe"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-light text-gray-400 mb-2">
            $ your.email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            className="w-full bg-black border border-white/20 px-4 py-3 text-white 
              focus:border-white/40 focus:outline-none transition-colors"
            placeholder="john@company.com"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-light text-gray-400 mb-2">
            $ your.company
          </label>
          <input
            type="text"
            id="company"
            name="company"
            required
            className="w-full bg-black border border-white/20 px-4 py-3 text-white 
              focus:border-white/40 focus:outline-none transition-colors"
            placeholder="Company Name"
            value={formData.company}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="goals" className="block text-sm font-light text-gray-400 mb-2">
            $ your.goals
          </label>
          <textarea
            id="goals"
            name="goals"
            required
            className="w-full bg-black border border-white/20 px-4 py-3 text-white 
              focus:border-white/40 focus:outline-none transition-colors h-32"
            placeholder="Tell us about your business goals..."
            value={formData.goals}
            onChange={handleChange}
          />
        </div>

        <button
          type="submit"
          className="w-full group bg-white text-black py-4 px-6 flex items-center 
            justify-center gap-2 hover:bg-gray-100 transition-colors font-mono"
        >
          Claim Your Free Consultation
          <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
        </button>
      </div>
    </form>
  );
};

export default ContactForm;