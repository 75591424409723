import React from 'react';
import TrustBadges from './components/TrustBadges';
import AnimatedBackground from './components/AnimatedBackground';
import HeroContent from './components/HeroContent';

const Hero = () => {
  return (
    <section className="relative min-h-screen flex items-center justify-center overflow-hidden">
      <AnimatedBackground />
      
      <div className="relative z-10 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <HeroContent />
        <TrustBadges />
      </div>
    </section>
  );
};

export default Hero;