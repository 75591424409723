import React from 'react';
import { StatisticsGridProps } from '../types/testimonials.types';

const StatisticsGrid: React.FC<StatisticsGridProps> = ({ statistics }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-16">
      {statistics.map((stat, index) => (
        <div key={index} className="text-center">
          <div className="text-3xl font-light mb-2 terminal-glow">{stat.value}</div>
          <div className="text-gray-400 text-sm font-light">{stat.label}</div>
        </div>
      ))}
    </div>
  );
};

export default StatisticsGrid;