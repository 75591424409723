import React from 'react';
import AboutHero from './components/AboutHero';
import Mission from './components/Mission';
import Vision from './components/Vision';
import Metrics from './components/Metrics';
import { aboutConfig } from './config/aboutConfig';

const About = () => {
  return (
    <section id="about" className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <AboutHero content={aboutConfig.hero} />
        <Mission content={aboutConfig.mission} />
        <Vision content={aboutConfig.vision} />
        <Metrics metrics={aboutConfig.metrics} />
      </div>
    </section>
  );
};

export default About;