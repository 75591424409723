import { MessageSquare, Calendar, Mail, Bot } from 'lucide-react';
import { ServiceConfig } from '../types/services.types';

export const servicesConfig: ServiceConfig[] = [
  {
    icon: MessageSquare,
    title: 'Customer Support Automation',
    description: 'Engage customers with AI-driven responses and 24/7 support.',
    link: '#contact'
  },
  {
    icon: Calendar,
    title: 'CRM Integrations',
    description: 'Integrate seamlessly with your CRM and schedule appointments effortlessly.',
    link: '#contact'
  },
  {
    icon: Mail,
    title: 'Email Marketing Automation',
    description: 'Craft personalized email campaigns that convert.',
    link: '#contact'
  },
  {
    icon: Bot,
    title: 'AI Chatbots',
    description: 'Intelligent conversational agents that understand and assist your customers.',
    link: '#contact'
  }
];