import React from 'react';
import { LucideIcon } from 'lucide-react';

interface ButtonProps {
  children: React.ReactNode;
  icon?: LucideIcon;
  className?: string;
  onClick?: () => void;
}

const Button = ({ children, icon: Icon, className = '', onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`group px-8 py-4 bg-white text-black font-mono font-semibold 
        hover:bg-gray-100 transform hover:scale-105 transition-all duration-200
        hover-glow flex items-center gap-2 ${className}`}
    >
      {children}
      {Icon && <Icon className="w-4 h-4 group-hover:translate-x-1 transition-transform" />}
    </button>
  );
};

export default Button;