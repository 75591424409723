import React from 'react';
import { ArrowRight } from 'lucide-react';
import Button from '../../../ui/Button';
import AlternatingTypewriter from '../../../ui/AlternatingTypewriter';
import { heroConfig } from '../config/heroConfig';

const HeroContent = () => {
  const { phrases, subtitle, ctaText } = heroConfig;
  
  return (
    <div className="text-center space-y-8">
      <div className="space-y-4">
        <h1 className="text-4xl md:text-6xl font-light tracking-tight">
          <AlternatingTypewriter
            phrases={phrases}
            className="inline-block terminal-glow"
            typingSpeed={80}
            deletingSpeed={40}
            delayBetweenPhrases={2000}
          />
        </h1>
        
        <p className="text-lg md:text-xl text-gray-400 font-light max-w-2xl mx-auto">
          {subtitle}
        </p>
      </div>
      
      <a href="#contact">
        <Button icon={ArrowRight} className="mx-auto">
          {ctaText}
        </Button>
      </a>
    </div>
  );
};

export default HeroContent;