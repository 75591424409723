import { Testimonial, Statistic } from '../types/testimonials.types';

export const testimonials: Testimonial[] = [
  {
    quote: "Since partnering with RR AI, our email open rates have increased by 30%, and customer inquiries are resolved 3x faster.",
    author: "John D.",
    position: "CEO",
    company: "Tech Startup",
    imageUrl: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80&w=200&h=200"
  },
  {
    quote: "The AI automation solutions have transformed our customer service. Response times dropped from hours to minutes.",
    author: "Sarah M.",
    position: "COO",
    company: "E-commerce Solutions",
    imageUrl: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=200&h=200"
  }
];

export const statistics: Statistic[] = [
  { value: "50+", label: "Businesses Automated" },
  { value: "500+", label: "Hours Saved Annually" },
  { value: "99.9%", label: "Uptime Guaranteed" },
  { value: "30%", label: "Average Efficiency Increase" }
];