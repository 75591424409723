import React from 'react';
import { ContactInfo as ContactInfoType } from '../types/footer.types';

interface ContactInfoProps {
  info: ContactInfoType;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ info }) => (
  <div>
    <h3 className="text-white font-semibold mb-4">Contact</h3>
    <ul className="space-y-2">
      <li>
        <a 
          href={`mailto:${info.email}`}
          className="text-gray-400 hover:text-white transition-colors duration-200"
        >
          {info.email}
        </a>
      </li>
    </ul>
  </div>
);

export default ContactInfo;