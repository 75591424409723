import React, { useState, useEffect, useCallback } from 'react';
import useSound from 'use-sound';

interface AlternatingTypewriterProps {
  phrases: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  delayBetweenPhrases?: number;
  className?: string;
}

const AlternatingTypewriter: React.FC<AlternatingTypewriterProps> = ({
  phrases,
  typingSpeed = 100,
  deletingSpeed = 50,
  delayBetweenPhrases = 2000,
  className = ''
}) => {
  const [text, setText] = useState('');
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCursor, setShowCursor] = useState(true);

  // Typing sound effect
  const [playTypingSound] = useSound('/typing-sound.mp3', { 
    volume: 0.15,
    interrupt: true
  });

  const animate = useCallback(() => {
    const currentPhrase = phrases[phraseIndex];
    
    if (isDeleting) {
      setText(prev => prev.slice(0, -1));
      playTypingSound();
      
      if (text === '') {
        setIsDeleting(false);
        setPhraseIndex(prev => (prev + 1) % phrases.length);
      }
    } else {
      setText(currentPhrase.slice(0, text.length + 1));
      playTypingSound();
      
      if (text === currentPhrase) {
        setTimeout(() => setIsDeleting(true), delayBetweenPhrases);
      }
    }
  }, [text, phraseIndex, isDeleting, phrases, delayBetweenPhrases, playTypingSound]);

  useEffect(() => {
    const timeout = setTimeout(
      animate,
      isDeleting ? deletingSpeed : typingSpeed
    );
    return () => clearTimeout(timeout);
  }, [animate, isDeleting, deletingSpeed, typingSpeed]);

  // Blinking cursor animation
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 530);
    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <div className={`font-mono ${className}`}>
      <span aria-label={text}>{text}</span>
      <span 
        className={`inline-block w-[2px] h-[1em] bg-white ml-1 align-middle
          ${showCursor ? 'opacity-100' : 'opacity-0'}`}
        aria-hidden="true"
      />
    </div>
  );
};

export default AlternatingTypewriter;